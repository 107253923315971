import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import Intro from "../components/intro"
import Newsletter from "../components/newsletter"
import Dustin from "../components/dustin"

const IndexPage = () => (
  <Layout>
    <SEO title="Ondergrond" />
    <Intro />
    <Newsletter />
    <Dustin />
  </Layout>
)

export default IndexPage
