import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default class Newsletter extends React.Component {
  state = {
    // name: null,
    email: null,
  }

  _handleChange = e => {
    // console.log({
    //   [`${e.target.name}`]: e.target.value,
    // })
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    // console.log("submit", this.state)

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log("msg", `${result}: ${msg}`)

        if (result !== "success") {
          throw msg
        }
        // alert(msg)
      })
      .catch(err => {
        console.log("err", err)
        alert(err)
      })
  }

  render() {
    return (
      <div className="newsletterSection">
				<div style={{
					maxWidth:'600px',
					margin: '30vh auto 20px',
					padding: '20px'
				}}>
          <p>
            Our quaint little bar is just that, a quaint little bar. We revel in
            filling all the glasses for you lads and lasses, but this may
            require modest decorum. We cannot accommodate all of you all the
            time, but some of you, some of the time. Waits are unfortunate, but
            necessary. If we are unable to seat your party, all is not lost dear
            friend. Please remember, there’s always another opportunity to be
            had.
          </p>

          <p>
            Observing these procedures will ensure our hidden locale does not
            exceed capacity and that the atmosphere remains true its intention.
          </p>

          <p>May we take this opportunity to share a word with you: </p>
        </div>
        <div
          style={{ margin: "0 auto", maxWidth: "900px", textAlign: "center" }}
        >
          <form onSubmit={this._handleSubmit}>
            <input
              type="email"
              onChange={this._handleChange}
              placeholder="email"
              name="email"
              style={{
                minWidth: "300px",
                marginRight: "10px",
                marginBottom: "50vh",
              }}
            />
            <input type="submit" />
          </form>
        </div>
      </div>
    )
  }
}
