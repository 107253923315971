import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import logo from "../images/favicon.png"

// import butchs from '../images/butchs-logo.svg'

// import bg from '../images/background.jpg'

import { Parallax } from "react-scroll-parallax"
import { motion } from "framer-motion"

const Intro = () => {
  const title = Array.from("ØndergrØnd")
  const containerVariants = {
    before: {},
    after: { transition: { staggerChildren: 0.16 } },
  }

  // Variants for animating each letter
  const letterVariants = {
    before: {
      opacity: 0,
      y: 20,
      scale: 0.92,
      transition: {
        type: "spring",
        damping: 66,
        stiffness: 500,
      },
    },
    after: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 76,
        stiffness: 200,
      },
    },
  }

  const variants = {
    before: { opacity: 0 },
    after: { opacity: 1 },
  }
  return (
    <div className="hello">
      <div className="key">
        <motion.img
        initial={"before"}
        animate={"after"}
        transition={{duration: 1.5, delay: .4}}
        variants={variants}
        style={{
          maxWidth: "160px",
        }}
        src={logo} alt="Undergrond Logo" />

        <motion.div
          center={"y"}
          height={26}
          width={"100%"}
          style={{
            fontWeight: 100,
            fontSize: 60,
            textTransform: "uppercase",
            display: "flex", // Set the display value to flex
            justifyContent: "center", // Center all children elements on the x axis
          }}
          variants={containerVariants}
          initial={"before"}
          animate={"after"}
        >
          {title.map((letter, index) => (
            <motion.div
              key={index}
              width={"auto"} // Set the width to the width of the letter
              height={26} // Set the height to the height of the text
              style={{ position: "relative" }} // Position elements
              variants={letterVariants}
            >
              {letter}
            </motion.div>
          ))}
        </motion.div>

        <motion.p
          initial={"before"}
          animate={"after"}
          transition={{ delay: 2.5, duration: 2}}
          variants={variants}
        >
          Mum's the word.
        </motion.p>
      </div>
    </div>
  )
}

export default Intro
