import React from "react"
// import { Link } from "gatsby"
// import Layout from "../components/layout"
// import SEO from "../components/seo"

import dustin from "../images/dustin-edit.jpg"

const Dustin = () => <img
style={{
  width: "100%",
  height: "100%",
}}
alt="Dustin Terhaar" src={dustin} />

export default Dustin
